<template>
  <div class="upload-avatar-page">
    <div class="panel">
      <form-avatar-uploader :user="user"
                            @avatarUploaded="onAvatarUploaded"/>
    </div>
  </div>
</template>

<script>
  import FormAvatarUploader from '@/components/Forms/FormAvatarUploader/FormAvatarUploader.vue'
  import profileService from '@/services/queries/profileQueries.js'

  export default {
    components: {
      FormAvatarUploader
    },

    props: {
      userId: {
        required: false,
        type: String
      }
    },

    data() {
      return {
        user: null
      }
    },

    created() {
      if (this.userId) {
        this.getUser()
      }
    },

    methods: {
      getUser() {
        profileService.find(this.userId).then(profile => this.user = profile)
      },

      onAvatarUploaded(avatar = null, avatarData = null) {
        this.$router.go(-1)
        this.setAvatarUploadState({ avatar, avatarData })
      },

      ...mapActions('system', [
        'setAvatarUploadState'
      ])
    }
  }
</script>

<style lang="scss" scoped>
  .upload-avatar-page {
    height: 100%;
    padding: 20px 0;
    background-color: #f8f8f8;

    .panel {
      max-width: 90%;
      height: 100%;
      margin: 0 auto;
      padding: 20px;
    }
  }
</style>
